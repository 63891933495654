/* jshint esversion: 6 */
const lateInits = [];

/**
 * @callback NowAndLaterCallback
 * @param {HTMLElement} element
 * @return void
 */

/**
 * @param {string} selector
 * @param {NowAndLaterCallback} callback
 */
export default function nowAndLater(selector, callback)
{
    const nodes = document.querySelectorAll(selector);
    const id = Date.now() + '_' + lateInits.length;
    const wrappedCallback = (node) => {
        node.dataset['nowAndLater' + id] = '';
        callback(node);
    };
    if (nodes.length > 0) {
        [...nodes].forEach(node => {
            wrappedCallback(node);
        });
    }
    lateInits.push({selector: selector, callback: wrappedCallback, id: id});
}

const observer = new MutationObserver(function (mutationsList)
{
    for (const mutation of mutationsList) {
        for (const addedNode of mutation.addedNodes) {
            for (const lateInit of lateInits) {
                if (addedNode['querySelector'] === undefined) { // lets filter out text and comment nodes
                    continue;
                }
                const selector = lateInit.selector + ':not([data-now-and-later' + lateInit.id + '])';
                // If the added node matches itself, it will not be found by querySelectorAll, so we have to check first
                if (addedNode.matches(selector)) {
                    lateInit.callback(addedNode);
                }
                // NOTE: the added not selector prevents double calls
                const nodes = addedNode.querySelectorAll(selector);
                if (nodes.length > 0) {
                    [...nodes].forEach(node => lateInit.callback(node));
                }
            }
        }
    }
});
observer.observe(document.documentElement, {childList: true, subtree: true});
